// Code from motionthat - part of home page
import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring/web.cjs";

const Wrapper = styled.svg`
  overflow: visible;

  .cls-1 {
    fill: #a6a8ab;
  }
  .cls-2 {
    fill: #e6e7e8;
  }
  .cls-3 {
    fill: #f1f1f2;
  }
  .bat {
    fill: #f1f1f2;
  }
  .cls-4 {
    fill: #252828;
  }
  .cls-5 {
    fill: #fff;
  }
  .cls-6 {
    font-size: 13.63944px;
  }
  .cls-6,
  .cls-8 {
    fill: #2cc3eb;
  }
  .cls-6,
  .cls-7 {
    font-family: VCROSDMono, VCR OSD Mono;
  }
  .cls-7 {
    font-size: 10.12272px;
    fill: #221f1f;
  }
  .cls-9 {
    fill: #84d3ed;
  }
`;

const RobotTwo = () => {
  const [leftArm1State, setLeftArm1State] = useState(true);
  const [leftArm2State, setLeftArm2State] = useState(true);
  const [rightArm1State, setRightArm1State] = useState(true);
  const [rightArm2State, setRightArm2State] = useState(true);
  const [ball, setBall] = useState(true);
  const [word, setWord] = useState(false);

  // Self called toggler to keep repeating it's left top arm movement
  const moveLeftArm1 = useSpring({
    transform: leftArm1State ? `rotate(0deg)` : `rotate(10deg)`,
    transformOrigin: `50% 50%`,
    onRest: () => setLeftArm1State(!leftArm1State),
    config: { mass: 1, tension: 280, friction: 120 },
  });

  // Self called toggler to keep repeating it's left bottom arm movement
  const moveLeftArm2 = useSpring({
    transform: leftArm2State ? `rotate(1deg)` : `rotate(-4deg)`,
    transformOrigin: `50% 50%`,
    onRest: () => setLeftArm2State(!leftArm1State),
    config: { mass: 1, tension: 280, friction: 120 },
  });

  // Self called toggler to keep repeating it's right top arm movement
  const moveRightArm1 = useSpring({
    transform: rightArm1State ? `rotate(-11deg)` : `rotate(7deg)`,
    transformOrigin: `50% 50%`,
    onRest: () => setRightArm1State(!rightArm1State),
    config: { mass: 1, tension: 200, friction: 120 },
  });

  // Self called toggler to keep repeating it's right bottom arm movement
  const moveRightArm2 = useSpring({
    transform: !rightArm2State ? `rotate(7deg)` : `rotate(0deg)`,
    transformOrigin: `50% 50%`,
    onRest: () => setRightArm2State(!rightArm2State),
    config: { mass: 3, tension: 280, friction: 120 },
  });

  // Self called toggler to keep repeating it's ball movement
  const moveBall = useSpring({
    transform: !ball ? `translate(10px, 10px)` : `translate(-29px, 6px)`,
    transformOrigin: `50% 50%`,
    onRest: () => setBall(!ball),
    config: { mass: 3, tension: 280, friction: 120 },
  });

  // Self called toggler to keep repeating it's blink effect
  const blinkWord = useSpring({
    opacity: word ? 0 : 1,
    onRest: () => setWord(!word),
    config: { mass: 2, tension: 478, friction: 40 },
  });

  return (
    <Wrapper viewBox="0 0 375 230">
      <defs />
      <g>
        <path
          className="cls-1"
          d="M276.792 50.608l-1.802-.951c2.935-5.487 1.498-10.852-.243-14.395-3.867-8.017-12.977-14.738-18.727-15.77-4.434-.79-7.855-.062-10.143 2.186-4.717 4.595-3.624 14.212-3.442 15.568h.243a1.132 1.132 0 0 1 1.134 1.134v9.556h-5.041V38.38a1.15 1.15 0 0 1 1.154-1.134h.465c-.283-2.247-1.154-11.904 4.07-17.006 2.773-2.733 6.802-3.664 11.904-2.753 6.357 1.154 15.994 8.2 20.205 16.885 2.774 5.689 2.834 11.296.223 16.236z"
          transform="translate(-42.67433 -17.14831)"
        />
        <rect
          className="cls-2"
          x="80.41603"
          y="30.41238"
          width="156.10615"
          height="164.65705"
          rx="11.07936"
        />
        <rect
          className="cls-3"
          x="123.00941"
          y="105.24887"
          width="70.91938"
          height="63.5263"
          rx="5.33608"
        />
        <rect
          className="cls-4"
          x="123.00941"
          y="57.35441"
          width="70.91938"
          height="42.21137"
          rx="4.34971"
        />
        <rect
          className="cls-5"
          x="126.83407"
          y="61.911"
          width="63.27006"
          height="33.09819"
          rx="4.06498"
        />
        <rect
          className="cls-1"
          x="90.35963"
          y="64.10844"
          width="7.30747"
          height="7.30747"
          rx="2.21587"
        />
        <rect
          className="cls-1"
          x="90.35963"
          y="154.0659"
          width="7.30747"
          height="7.30747"
          rx="2.21587"
        />
        <rect
          className="cls-1"
          x="196.31536"
          y="196.47201"
          width="28.65961"
          height="7.74249"
          rx="3.87124"
        />
        <rect
          className="cls-1"
          x="91.96323"
          y="196.47201"
          width="28.65961"
          height="7.74249"
          rx="3.87124"
        />
      </g>
      <text className="cls-6" transform="translate(142.48529 141.7838)">
        NORM
      </text>
      <animated.text
        style={blinkWord}
        className="cls-7"
        transform="translate(142.96192 81.88243)"
      >
        hello
      </animated.text>
      <animated.g style={moveRightArm2}>
        <rect
          className="cls-1"
          x="301.72497"
          y="158.05852"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(-84.84287 120.07695) rotate(-23.43882)"
        />
        <rect
          className="cls-1"
          x="317.2498"
          y="164.03812"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(-82.4386 123.2348) rotate(-23.43892)"
        />
        <path
          className="cls-1"
          d="M300.49 171.915a1.232 1.232 0 0 1 1.231 1.231v1.53a1.232 1.232 0 0 1-1.232 1.232 1.232 1.232 0 0 1-1.231-1.231v-1.53a1.232 1.232 0 0 1 1.231-1.232z"
          transform="translate(-87.05633 116.72817) rotate(-23.43893)"
        />
        <path
          className="cls-1"
          d="M316.603 181.783a.758.758 0 0 0-.453 1.314l1.715 1.57.412-.123c.052-.016 4.743-1.377 7.608 1.247 2.82 2.582 1.428 6.859 1.413 6.901a.758.758 0 0 0 1.436.486c.072-.212 1.731-5.248-1.825-8.505-2.98-2.727-7.27-2.013-8.642-1.692l-1.093-1.002a.757.757 0 0 0-.571-.196z"
          transform="translate(-42.67433 -17.14831)"
        />
        <rect
          className="cls-1"
          x="301.72497"
          y="158.05852"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(-84.84287 120.07695) rotate(-23.43882)"
        />
        <rect
          className="cls-1"
          x="317.2498"
          y="164.03812"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(-82.4386 123.2348) rotate(-23.43892)"
        />
        <path
          className="cls-1"
          d="M300.49 171.915a1.232 1.232 0 0 1 1.231 1.231v1.53a1.232 1.232 0 0 1-1.232 1.232 1.232 1.232 0 0 1-1.231-1.231v-1.53a1.232 1.232 0 0 1 1.231-1.232z"
          transform="translate(-87.05633 116.72817) rotate(-23.43893)"
        />
        <path
          className="cls-1"
          d="M315.256 182.933a.758.758 0 0 0-1.342.364l-.214 1.468c-1.262.615-5.043 2.775-5.628 6.773-.694 4.773 3.774 7.625 3.963 7.744a.758.758 0 0 0 .805-1.283c-.038-.025-3.824-2.46-3.268-6.24.562-3.85 5.062-5.758 5.108-5.777l.396-.165.338-2.3a.757.757 0 0 0-.158-.584z"
          transform="translate(-42.67433 -17.14831)"
        />
        <rect
          className="cls-1"
          x="237.80515"
          y="123.84634"
          width="4.58253"
          height="16.11854"
          rx="2.21587"
        />
        <path
          className="cls-2"
          d="M286.494 156.734s2.921-.734 7.777.664a5.988 5.988 0 0 1 3.342 3.24l12.758-5.468s-2.844-8.98-10.88-12.03c-7.797-2.961-12.997-1.552-12.997-1.552z"
          transform="translate(-42.67433 -17.14831)"
        />
      </animated.g>
      <animated.g style={moveLeftArm1}>
        <rect
          className="cls-1"
          x="117.21801"
          y="122.21657"
          width="4.58253"
          height="16.11854"
          rx="2.21587"
          transform="translate(196.34422 243.40336) rotate(180)"
        />
        <rect
          className="cls-1"
          x="84.67698"
          y="97.10473"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(177.4184 157.53775) rotate(157.435)"
        />
        <path
          className="cls-1"
          d="M83.746 111.382a1.232 1.232 0 0 1 1.231 1.232v1.53a1.232 1.232 0 0 1-1.231 1.232 1.232 1.232 0 0 1-1.232-1.232v-1.53a1.232 1.232 0 0 1 1.232-1.232z"
          transform="translate(161.91355 168.79389) rotate(157.43484)"
        />
        <rect
          className="cls-1"
          x="100.62427"
          y="103.78105"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(193.82998 147.22402) rotate(157.43497)"
        />
        <rect
          className="cls-1"
          x="84.67698"
          y="97.10473"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(177.4184 157.53775) rotate(157.435)"
        />
        <path
          className="cls-1"
          d="M83.746 111.382a1.232 1.232 0 0 1 1.231 1.232v1.53a1.232 1.232 0 0 1-1.231 1.232 1.232 1.232 0 0 1-1.232-1.232v-1.53a1.232 1.232 0 0 1 1.232-1.232z"
          transform="translate(161.91355 168.79389) rotate(157.43484)"
        />
        <rect
          className="cls-1"
          x="100.62427"
          y="103.78105"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(193.82998 147.22402) rotate(157.43497)"
        />
        <path
          className="cls-1"
          d="M85.771 90.3l2.381 5.606a.776.776 0 1 1-1.427.607l-2.381-5.607a.776.776 0 1 1 1.427-.607z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-1"
          d="M113.958 78.33a.776.776 0 0 1-.411 1.017l-56.372 23.939a.776.776 0 0 1-.607-1.428l56.373-23.939a.776.776 0 0 1 1.017.411z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-2"
          d="M115.786 123.113a17.227 17.227 0 0 1-7.048-.894 9.3 9.3 0 0 1-4.07-3.526l-13.281 5.644s3.366 8.802 11.402 11.853c7.798 2.961 12.997 1.551 12.997 1.551zM64.64 96.811l41.4-17.355s-1.697-1.909 0-8.272-6.046-.088-7.955 3.297-5.413.203-5.464-5.1-7.156-7.318-10.02 1.485-16.65-7.825-20.256-6.034-.212 13.458 2.545 17.276-7.424 2.97-9.651 4.56 4.348 3.541 4.878 4.9-.808.675-2.227 1.04c-4.666 1.197 6.75 4.203 6.75 4.203z"
          transform="translate(-42.67433 -17.14831)"
        />
        <circle className="cls-5" cx="26.40105" cy="70.50776" r="0.99705" />
        <circle className="cls-5" cx="24.19778" cy="72.96901" r="0.68534" />
        <circle className="cls-5" cx="27.39113" cy="73.29671" r="0.68534" />
      </animated.g>
      <animated.g style={moveRightArm1}>
        <rect
          className="cls-1"
          x="237.80515"
          y="105.0644"
          width="4.58253"
          height="16.11854"
          rx="2.21587"
        />
        <rect
          className="cls-1"
          x="318.93978"
          y="86.28148"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(87.09654 -197.41544) rotate(37.9688)"
        />
        <path
          className="cls-1"
          d="M334.356 102.77a1.232 1.232 0 0 1 1.232 1.232v1.53a1.232 1.232 0 0 1-1.232 1.231 1.232 1.232 0 0 1-1.231-1.231v-1.53a1.232 1.232 0 0 1 1.231-1.232z"
          transform="translate(92.55006 -200.68131) rotate(37.96892)"
        />
        <rect
          className="cls-1"
          x="317.68458"
          y="90.63134"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(81.81375 -193.75099) rotate(37.96889)"
        />
        <path
          className="cls-1"
          d="M334.145 87.417a.758.758 0 0 1-.665-1.221l.913-1.17c-.427-1.336-1.478-5.562 1.007-8.748 2.97-3.8 8.12-2.54 8.337-2.487a.758.758 0 0 1-.371 1.47c-.045-.012-4.421-1.065-6.77 1.95-2.393 3.065-.663 7.637-.645 7.683l.154.4-1.429 1.834a.757.757 0 0 1-.53.289z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="bat"
          d="M398.781 53.76a7.235 7.235 0 0 0-9.641-3.648c-1.854.836-18.35 9.204-33.753 17.73l4.112 9.117c16.474-5.94 33.78-12.72 35.634-13.557a7.235 7.235 0 0 0 3.648-9.641zM354.08 68.71c-6.169 3.433-11.917 6.769-16.162 9.52l-.349-.773a2.204 2.204 0 1 0-4.018 1.812l.627 1.39 1.673 3.71.585 1.503a2.204 2.204 0 0 0 4.017-1.812l-.348-.773c4.915-1.472 11.22-3.572 17.835-5.81z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-1"
          d="M336.692 89.405a.758.758 0 0 1-.664-1.221l1.43-1.834.427.054c.054.007 4.906.571 7.296-2.492 2.352-3.014.27-7 .249-7.04a.758.758 0 0 1 1.335-.718c.107.198 2.578 4.888-.388 8.69-2.486 3.184-6.836 3.192-8.243 3.103l-.91 1.169a.757.757 0 0 1-.532.289z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-2"
          d="M323.648 113.992s-8.995 15.912-18.282 20.018a49.63 49.63 0 0 1-18.874 3.734v-15.149s4.816.757 11.713-1.936c7.314-2.855 14.866-15.559 14.866-15.559z"
          transform="translate(-42.67433 -17.14831)"
        />
      </animated.g>
      <animated.g style={moveLeftArm2}>
        <path
          className="cls-2"
          d="M78.295 150.672l-21.874 7.782a6.14 6.14 0 0 0-3.5 3.15 6.832 6.832 0 0 1-3.897 3.51l-3.298 1.172-.073-.205a.517.517 0 0 0-.66-.314l-1.357.483a1.448 1.448 0 0 0-.878 1.847l1.99 5.596a1.448 1.448 0 0 0 1.848.877l1.357-.482a.517.517 0 0 0 .314-.66l-.073-.206 3.297-1.173a6.83 6.83 0 0 1 5.237.259 6.137 6.137 0 0 0 4.704.232l21.874-7.781a2.042 2.042 0 0 0 1.238-2.605L80.9 151.911a2.043 2.043 0 0 0-2.605-1.239zM61.898 164.44c-.73-2.053 1.198-4.621 4.298-5.724s6.216-.33 6.947 1.724-1.198 4.622-4.298 5.725-6.216.329-6.947-1.725z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-3"
          d="M74.118 160.094a4.547 4.547 0 0 1-.982 4.308l.906 2.556 8.919-3.172a1.006 1.006 0 0 0 .61-1.283l-2.618-7.361a5.534 5.534 0 0 1-2.146 3.395 11.237 11.237 0 0 1-4.967.964 3.54 3.54 0 0 1 .278.593zM47.69 171.805l.158.446 3.295-1.177a7.865 7.865 0 0 1 6.026.302 5.123 5.123 0 0 0 3.919.19l4.308-1.534-.89-2.5a4.146 4.146 0 0 1-2.534-7.134l-.231-.641a2.859 2.859 0 0 0-2.056 1.159c-1.6 3.054.509 5-1.216 6.066-1.73 1.064-3.274.13-4.847.456-1.516.31-3.8 2.983-5.932 4.367zm-2.141 1.03l.175.509a.411.411 0 0 0 .529.252l.866-.31-.312-.895a3.731 3.731 0 0 1-1.258.445z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-5"
          d="M80.915 163.611a.75.75 0 0 0 0-1.5.75.75 0 0 0 0 1.5zm.242-2.822a.5.5 0 0 0 0-1 .5.5 0 0 0 0 1z"
          transform="translate(-42.67433 -17.14831)"
        />
        <ellipse
          className="cls-5"
          cx="67.52053"
          cy="162.44035"
          rx="5.96735"
          ry="3.95307"
          transform="translate(-93.21218 14.8769) rotate(-19.58211)"
        />
        <circle className="cls-5" cx="5.68795" cy="153.26678" r="0.25237" />
        <circle className="cls-5" cx="19.95935" cy="152.20627" r="0.25237" />
        <circle className="cls-3" cx="6.43584" cy="151.32735" r="0.1179" />
        <circle className="cls-3" cx="14.76145" cy="149.27189" r="0.22402" />
        <circle className="cls-3" cx="31.0692" cy="140.25036" r="0.22402" />
        <circle className="cls-3" cx="31.9768" cy="140.99943" r="0.32735" />
        <circle className="cls-5" cx="34.58486" cy="138.54407" r="0.52061" />
        <circle className="cls-5" cx="15.48149" cy="152.35999" r="0.19856" />
        <circle className="cls-5" cx="14.40726" cy="151.50615" r="0.12983" />
        <circle className="cls-5" cx="14.52868" cy="149.19919" r="0.12983" />
        <circle className="cls-5" cx="13.90428" cy="152.83947" r="0.12983" />
        <circle className="cls-5" cx="12.0646" cy="150.1851" r="0.12983" />
        <circle className="cls-5" cx="11.56876" cy="150.17426" r="0.08538" />
        <circle className="cls-5" cx="19.60048" cy="151.55326" r="0.08538" />
        <circle className="cls-3" cx="11.32658" cy="150.00052" r="0.08538" />
        <circle className="cls-5" cx="15.57948" cy="153.21851" r="0.42429" />
        <rect
          className="cls-1"
          x="81.21079"
          y="143.20221"
          width="15.88855"
          height="24.38556"
          rx="5.53968"
          transform="translate(-67.87421 276.15669) rotate(-110.49022)"
        />
        <rect
          className="cls-1"
          x="84.84668"
          y="144.12656"
          width="2.46309"
          height="3.99341"
          rx="1.23154"
          transform="translate(-63.34284 260.75736) rotate(-110.49028)"
        />
        <path
          className="cls-1"
          d="M92.888 162.549a1.232 1.232 0 0 1 1.231 1.231v1.53a1.232 1.232 0 0 1-1.231 1.232 1.232 1.232 0 0 1-1.232-1.231v-1.53a1.232 1.232 0 0 1 1.232-1.232z"
          transform="translate(-71.40681 292.0068) rotate(-110.49018)"
        />
        <rect
          className="cls-1"
          x="117.21801"
          y="141.18701"
          width="4.58253"
          height="16.11854"
          rx="2.21587"
          transform="translate(196.34421 281.34425) rotate(-180)"
        />
        <path
          className="cls-1"
          d="M75.38 162.838a11.82 11.82 0 0 1-1.731 5.376 7.432 7.432 0 0 1-2.478 2.364 7.153 7.153 0 0 1-5.759.568l4.14-1.479a5.361 5.361 0 0 0 .863-.401c3.362-1.947 3.468-6.833 3.47-6.884l.007-.43 2.01-1.17a.763.763 0 0 1 1.038.28.74.74 0 0 1 .08.548.764.764 0 0 1-.358.486zm.734-3.955a.73.73 0 0 1-.543.267l-2.319.11-.238-.356c-.03-.044-2.76-4.096-6.646-3.912a1.741 1.741 0 0 0-.192.014 5.302 5.302 0 0 0-2.52.893l-3.23 1.152a7.566 7.566 0 0 1 5.87-3.574 7.316 7.316 0 0 1 2.886.456 11.43 11.43 0 0 1 4.838 3.774l1.484-.07a.757.757 0 0 1 .79.723.736.736 0 0 1-.18.523z"
          transform="translate(-42.67433 -17.14831)"
        />
        <path
          className="cls-2"
          d="M99.386 143.864l5.288 13.612a21.654 21.654 0 0 1 4.975-.992 29.075 29.075 0 0 1 6.036.069l.032-15.13a20.586 20.586 0 0 0-8.28.085 43.672 43.672 0 0 0-8.05 2.356z"
          transform="translate(-42.67433 -17.14831)"
        />
      </animated.g>
      <animated.g style={moveBall} id="ball">
        <circle
          data-name="Ellipse 1"
          className="cls-8"
          cx="308.4162"
          cy="182.40196"
          r="10.85724"
        />
        <path
          className="cls-9"
          d="M359.628 197.875a.517.517 0 0 1-.507-.418 7.851 7.851 0 0 0-4.908-5.848.517.517 0 0 1 .36-.97 8.893 8.893 0 0 1 5.562 6.62.517.517 0 0 1-.507.616z"
          transform="translate(-42.67433 -17.14831)"
        />
        <circle className="cls-9" cx="317.16194" cy="182.03557" r="0.55039" />
      </animated.g>
    </Wrapper>
  );
};

export default RobotTwo;
