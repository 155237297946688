import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled, { keyframes } from "styled-components";
import {
  mainBlack,
  mainGray,
  amatic,
  graphik,
  mainWhite,
  larsseit,
  screen,
  transHover,
} from "../../components/common/variables";
import { Waypoint } from "react-waypoint";
import { motion } from "framer-motion";
import LabeledArrow from "../../components/labeled-arrow";
import ScrollHint from "../../components/scroll-hint";
import wireframeVideo from "../../videos/handsome-creative-motionthat-website-process.mp4";
import websiteVideo from "../../videos/handsome-creative-motion-that-screen-design.mp4";
import phoneVideo from "../../videos/handsome-creative-motionthat.mp4";
import AnimatedNorm from "../../images/case-studies/motionthat/norm";
import ArrowCurved from "../../images/case-studies/source-separation-systems/svg/curved-arrow.svg";
import Logo from "../../images/case-studies/motionthat/svg/motion-that-brand.svg";
import startedTopBg from "../../images/case-studies/motionthat/handsome-creative-motionthat.png";
import siteNormBg from "../../images/case-studies/motionthat/handsome-creative-motionthat-precision-movement.jpg";
import LogoMockupLg from "../../images/case-studies/motionthat/svg/handsome-creative-motionthat-logo-development-desktop.svg";
import NormArm from "../../images/case-studies/motionthat/svg/handsome-creative-motionthat-business-card-design-print.svg";
import LogoMockupSm from "../../images/case-studies/motionthat/svg/handsome-creative-motionthat-logo-development-mobile.svg";
import ContactForm from "../../components/contact-form";
import sticker from "../../images/case-studies/motionthat/handsome-creative-motionthat-sticker-3.png";

const animateSticker1 = keyframes`
0% {
  transform: translateY(120%) translateX(-50%) rotate(-10deg);
  opacity: 0;
}
90% {
  opacity: 0.9;
}
100% {
  transform: translateY(0) translateX(-50%) rotate(2deg);
  opacity: 1;
}
`;

const animateSticker2 = keyframes`
0% {
  transform: translateX(-200%) rotate(-30deg);
  opacity: 0;
}
30% {
  opacity: 0.9;
}
100% {
  transform: translateX(-50%) rotate(-4deg);
  opacity: 1;
}
`;

const animateSticker3 = keyframes`
0% {
  transform: translateX(120%) rotate(40deg);
  opacity: 0;
}
30% {
  opacity: 0.9;
}
100% {
  transform: translateX(-50%) rotate(4deg);
  opacity: 1;
}
`;

const Wrapper = styled.div`
  color: ${mainBlack};
  font-family: ${graphik};
  position: relative;

  .main {
    padding: 0 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.17;
      margin-bottom: 5px;
      @media ${screen.medium} {
        font-size: clamp(1.1rem, calc(0.88rem + 1vw), 2.1rem);
      }
    }

    &__subheading {
      color: ${mainGray};
      font-size: 1.1rem;
      font-style: italic;
      margin-bottom: 30px;
      @media ${screen.small} {
        margin-bottom: 40px;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
    }

    &__description {
      max-width: 962px;
      position: relative;
      z-index: 3;

      p {
        font-size: 1.1rem;
        margin-bottom: 34px;
        @media ${screen.small} {
          font-size: 1.15rem;
          margin-bottom: 40px;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__web-link {
      color: ${mainGray};
      display: inline-block;
      font-size: 1.1rem;
      margin: 0 0 55px 0;
      position: relative;
      z-index: 3;
      transition: ${transHover};
      @media ${screen.xsmall} {
        margin: 0 0 60px 0;
      }
      @media ${screen.small} {
        font-size: 1.15rem;
        margin: 32px 0 70px 0;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }

      @media ${screen.withCursor} {
        &:hover {
          color: #f18f01;
        }
      }
    }

    &::after {
      content: "";
      background: ${mainWhite};
      position: absolute;
      bottom: 0;
      z-index: 2;
      width: 150%;
      left: 50%;
      height: 400px;
      transform: rotate(-4deg) translateX(-50%);
    }
  }

  .where-started {
    background-color: #eeeeee;
    background-image: url(${startedTopBg});
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: top;
    padding: 130px 26px 60px 26px;
    @media ${screen.xsmall} {
      padding: 150px 60px 70px 60px;
      background-size: cover;
    }
    @media ${screen.small} {
      padding: 170px 80px 90px 80px;
    }
    @media ${screen.large} {
      padding: 200px 80px 90px 80px;
    }

    @media ${screen.xlarge} {
      padding: 260px 7vw 130px 7vw;
    }

    &__text {
      max-width: 700px;
      margin: 0 0 0 auto;
      text-align: right;

      h2 {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin-bottom: 20px;
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
        @media ${screen.xlarge} {
          margin-bottom: 30px;
        }
      }

      p {
        font-size: 1.1rem;
        margin: 0 auto;
        padding: 0 26px;
        padding: 0;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__img {
      margin: 60px 0 0 0;
      &--lg {
        display: none;
        @media ${screen.xsmall} {
          display: block;
        }
      }

      &--sm {
        display: block;
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }
  }

  .site-and-norm {
    background-color: #090909;
    background-image: url(${siteNormBg});
    background-size: 660px;
    background-position: top;
    background-repeat: no-repeat;
    color: ${mainWhite};
    padding: 90px 26px 0 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 180px 60px 50px 60px;
      background-size: cover;
    }
    @media ${screen.small} {
      padding: 180px 80px 120px 80px;
    }
    @media ${screen.medium} {
      padding: 280px 7vw 150px 7vw;
    }

    &__logo {
      max-width: 250px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      @media ${screen.xsmall} {
        max-width: 350px;
      }
      @media ${screen.medium} {
        max-width: 420px;
      }
      @media ${screen.large} {
        max-width: 480px;
      }
    }

    &__flex-row {
      display: flex;
      margin: 90px 0 0 0;
      justify-content: center;
      position: relative;
      z-index: 10;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
        align-items: center;
        margin: 200px 0 0 0;
      }

      .animated-norm {
        max-width: 650px;
        width: 100%;
        margin: 0 0 0 20px;
        @media ${screen.xsmall} {
          margin: 0 10px 0 0;
        }
        @media ${screen.small} {
          margin: 0 70px 0 0;
        }
      }

      .text {
        .heading {
          font-weight: 500;
          line-height: 1.17;
          font-size: 2.3rem;
          font-family: ${larsseit};
          margin: 30px 0 20px 0;
          @media ${screen.xsmall} {
            margin: 0 0 20px 0;
          }
          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.medium} {
            font-size: 3rem;
          }
          @media ${screen.xlarge} {
            margin: 0 0 30px 0;
          }
        }

        .description {
          font-size: 1.1rem;
          max-width: 490px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            font-size: 1.33rem;
          }
        }
      }
    }

    &__laptop-screen {
      position: relative;
      z-index: 10;
      padding: 0;
      text-align: center;
      max-width: 1100px;
      margin: 130px auto 0 auto;
      @media ${screen.xsmall} {
        margin: 170px auto 0 auto;
      }
      @media ${screen.small} {
        margin: 200px auto 0 auto;
      }
      @media ${screen.large} {
        margin: 240px auto 0 auto;
      }

      video {
        display: block;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
      }
    }

    &::before {
      content: "";
      position: absolute;
      background: rgba(9, 9, 9, 0.65);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &::after {
      content: "";
      background: #060606;
      position: absolute;
      bottom: -100px;
      z-index: 2;
      width: 150%;
      left: 50%;
      height: 400px;
      transform: rotate(4deg) translateX(-50%);
      @media ${screen.xsmall} {
        bottom: -150px;
      }
      @media ${screen.xlarge} {
        bottom: -170px;
      }
    }
  }

  .wireframes {
    padding: 144px 26px 0 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 200px 60px 0 60px;
    }
    @media ${screen.small} {
      padding: 200px 80px 0 80px;
    }
    @media ${screen.medium} {
      padding: 200px 7vw 0 7vw;
    }

    &__text {
      .heading {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin: 0 0 20px 0;
        max-width: 550px;
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
        @media ${screen.xlarge} {
          margin: 0 0 30px 0;
        }
      }

      .description {
        font-size: 1.1rem;
        max-width: 668px;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__video {
      margin: 14px 0 0 0;
      pointer-events: none;
      @media ${screen.xsmall} {
        margin: 50px 0 0 0;
      }
      @media ${screen.small} {
        margin: 0;
      }

      video {
        display: block;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
      }
    }
  }

  .responsive {
    padding: 90px 26px 55px 26px;
    position: relative;
    z-index: 6;
    @media ${screen.xsmall} {
      padding: 200px 60px 200px 60px;
    }
    @media ${screen.small} {
      padding: 200px 80px 200px 80px;
    }
    @media ${screen.medium} {
      padding: 200px 7vw 300px 7vw;
    }

    &__flex-row {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }

      .text {
        @media ${screen.xsmall} {
          width: 50%;
          max-width: 590px;
        }

        .heading {
          font-weight: 500;
          line-height: 1.17;
          font-size: 2.3rem;
          font-family: ${larsseit};
          margin: 0 0 20px 0;
          max-width: 430px;
          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.medium} {
            font-size: 3rem;
          }
          @media ${screen.xlarge} {
            margin: 0 0 30px 0;
          }
        }

        .description {
          font-size: 1.1rem;
          max-width: 590px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            font-size: 1.33rem;
          }
        }
      }

      .phone {
        margin: 45px 0 0 -30px;
        @media ${screen.xsmall} {
          margin: 0;
          width: 50%;
          text-align: center;
        }

        video {
          display: block;
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          max-width: 220px;
          margin: 0 auto;
          @media ${screen.xsmall} {
            margin: 0 -40px 0 auto;
            max-width: 650px;
          }
          @media ${screen.large} {
            margin: 0 -180px 0 auto;
          }
          @media ${screen.xlarge} {
            margin: 0 -100px 0 auto;
          }
        }
      }
    }
  }

  .testimonial {
    background: #f19610;
    color: ${mainWhite};
    font-family: ${larsseit};
    padding: 85px 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 150px 60px 138px 60px;
    }
    @media ${screen.small} {
      padding: 170px 80px 170px 80px;
    }
    @media ${screen.medium} {
      padding: 190px 11vw 160px 11vw;
    }
    @media ${screen.xlarge} {
      padding: 220px 11vw 160px 11vw;
    }

    .testimonial-quotes {
      display: block;
      font-size: 6rem;
      line-height: 1;
      margin-bottom: -50px;
      position: relative;
      z-index: 4;
      @media ${screen.xsmall} {
        font-size: 6.6rem;
      }
      @media ${screen.small} {
        margin-bottom: -40px;
      }
      @media ${screen.medium} {
        margin-bottom: -76px;
        font-size: 11rem;
      }
    }

    .testimonial-msg {
      max-width: 1000px;
      position: relative;
      z-index: 4;

      p {
        font-weight: 100;
        line-height: 1.35;
        font-size: 1.75rem;
        @media ${screen.small} {
          font-size: 2.5rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }

        strong {
          font-weight: 700;
        }
      }
    }

    .testimonial-fullname {
      margin-top: 26px;
      position: relative;
      z-index: 4;
      font-size: 1rem;
      @media ${screen.xsmall} {
        font-size: 0.88rem;
        margin-bottom: 4px;
        margin-top: 30px;
      }
      @media ${screen.small} {
        margin-top: 60px;
        font-size: 1.1rem;
      }
      @media ${screen.medium} {
        font-size: clamp(1.2rem, calc(0.3rem + 1vw), 1.53rem);
        margin-top: 65px;
      }
    }

    .testimonial-company {
      position: relative;
      z-index: 4;
      font-size: 1rem;
      @media ${screen.xsmall} {
        font-size: 0.88rem;
      }
      @media ${screen.small} {
        font-size: 1.1rem;
      }
      @media ${screen.medium} {
        font-size: clamp(1.2rem, calc(0.3rem + 1vw), 1.53rem);
        margin-bottom: 65px;
      }
    }

    &::before {
      content: "";
      background: red;
      background: ${mainWhite};
      z-index: 3;
      position: absolute;
      width: 150%;
      height: 350px;
      top: -350px;
      left: 50%;
      transform: rotate(-4deg) translateX(-50%);
    }

    &::after {
      content: "";
      background: #eeeeee;
      z-index: 3;
      position: absolute;
      width: 150%;
      height: 350px;
      bottom: -350px;
      left: 50%;
      transform: rotate(4deg) translateX(-50%);
    }
  }

  .print {
    background: #eeeeee;
    padding: 266px 26px 44px 26px;
    position: relative;
    @media ${screen.xsmall} {
      padding: 60px 60px;
    }
    @media ${screen.small} {
      padding: 90px 80px;
    }
    @media ${screen.medium} {
      padding: 190px 7vw 100px 7vw;
    }
    @media ${screen.xlarge} {
      padding: 200px 7vw 100px 7vw;
    }

    &__norm-arm {
      position: absolute;
      max-width: 285px;
      width: 100%;
      right: 0;
      top: -20px;
      z-index: 7;
      @media ${screen.xsmall} {
        top: -130px;
        max-width: calc(180px + 13vw);
      }
      @media ${screen.small} {
        max-width: calc(300px + 15vw);
        top: -100px;
      }
      @media ${screen.medium} {
        top: -100px;
      }
      @media ${screen.large} {
        top: 10px;
      }
    }

    &__text {
      max-width: 550px;
      position: relative;
      z-index: 8;

      .heading {
        font-weight: 500;
        line-height: 1.17;
        font-size: 2.3rem;
        font-family: ${larsseit};
        margin: 0 0 20px 0;
        @media ${screen.small} {
          font-size: 2.9rem;
        }
        @media ${screen.medium} {
          font-size: 3rem;
        }
        @media ${screen.xlarge} {
          margin: 0 0 30px 0;
        }
      }

      .description {
        font-size: 1.1rem;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
        }
      }
    }

    &__img {
      margin: 40px 0 0 0;
      pointer-events: none;
      position: relative;
      z-index: 6;
      max-width: 1100px;
      @media ${screen.xsmall} {
        margin: 50px 0 0 0;
      }
    }

    &::after {
      content: "";
      background: #eeeeee;
      z-index: 3;
      position: absolute;
      width: 150%;
      height: 350px;
      bottom: 0;
      left: 50%;
      transform: rotate(-4deg) translateX(-50%);
    }
  }

  .stickers {
    padding: 110px 26px 0 26px;
    height: 510px;
    @media ${screen.xsmall} {
      padding: 240px 60px 0 60px;
      height: 900px;
    }
    @media ${screen.small} {
      padding: 240px 80px 0 80px;
      height: 1000px;
    }
    @media ${screen.medium} {
      padding: 270px 7vw 0 7vw;
    }

    &__wrapper {
      position: relative;
      z-index: 10;

      .sticker {
        position: absolute;
        width: 100%;

        &--one {
          max-width: 300px;
          left: 50%;
          z-index: 1;
          opacity: 0;
          animation: ${animateSticker1} 0.9s forwards ease-in-out;
          @media ${screen.xsmall} {
            max-width: 550px;
          }
          @media ${screen.large} {
            max-width: 650px;
          }
        }

        &--two {
          max-width: 230px;
          left: calc(50% - 50px);
          top: 77px;
          opacity: 0;
          animation: ${animateSticker2} 1.7s forwards ease-in-out;
          z-index: 2;
          @media ${screen.xsmall} {
            max-width: 420px;
            left: calc(50% - 180px);
            top: 140px;
          }
          @media ${screen.large} {
            max-width: 500px;
            top: 160px;
            left: calc(50% - 200px);
          }
        }

        &--three {
          max-width: 200px;
          left: calc(50% + 10px);
          top: 190px;
          z-index: 3;
          opacity: 0;
          animation: ${animateSticker3} 2.3s forwards ease-in-out;
          @media ${screen.xsmall} {
            max-width: 370px;
            left: calc(50% + 180px);
            top: 210px;
          }
          @media ${screen.large} {
            max-width: 440px;
            top: 240px;
            left: calc(50% + 220px);
          }
        }
      }
    }

    &__pointer {
      position: absolute;
      top: 138px;
      display: flex;
      right: 0;
      z-index: 4;
      @media ${screen.xsmall} {
        top: -50px;
        right: 0;
      }
      @media ${screen.small} {
        top: 150px;
      }
      @media ${screen.large} {
        right: 100px;
        top: 200px;
      }

      svg {
        width: 40px;
        transform: translate(38px, -97px) rotate(-30deg);
        display: block;
        @media ${screen.xsmall} {
          width: 57px;
          transform: translate(20px, -85px) rotate(-30deg);
        }
      }

      p {
        color: ${mainBlack};
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.3;
        text-transform: uppercase;
        font-family: ${amatic};
        max-width: 80px;
        text-align: right;
        @media ${screen.xsmall} {
          font-size: 1.55rem;
          max-width: none;
          text-align: left;
        }
      }
    }
  }

  .next-btn {
    display: flex;
    justify-content: center;
    padding: 0 0 35px 0;
    @media ${screen.small} {
      padding: 0 0 70px 0;
    }
  }
`;

const MotionThatPage = ({ data }) => {
  const [isStickers, setIsStickers] = useState(false);

  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title="MotionThat | Handsome Creative"
        description="MotionThat is on a mission to fill a void in Tabletop Product
        Shooting in the film industry. Their approach to purpose-designed
        software coupled with a high-speed motion control system to
        deliver accurate."
        image={sticker}
        webPageInfo={{
          name: "MotionThat website by Handsome Creative",
          url,
          description:
            "MotionThat is on a mission to fill a void in Tabletop Product Shooting in the film industry. Their approach to purpose-designed software coupled with a high-speed motion control system to deliver accurate",
        }}
      />
      <Wrapper>
        <ScrollHint />
        <div className="main">
          <h1 className="main__heading">MotionThat</h1>
          <h6 className="main__subheading">
            Branding, web design, printed collateral
          </h6>
          <div className="main__description">
            <p>
              MotionThat is on a mission to fill a void in Tabletop Product
              Shooting in the film industry. Their approach to purpose-designed
              software coupled with a high-speed motion control system to
              deliver accurate, consistent and controlled shots is already
              proving a big hit with customers.
            </p>
            <p>
              MotionThat needed an identity from the ground up. Key deliverables
              included a logo, colour palette, business cards, stickers and a
              fast loading responsive website with high performing SEO. Working
              together with Handsome Creative, NORM was born. A character to be
              at the forefront of the computer software powering the brand. By
              taking a digitalised personal approach from day one, MotionThat
              has been able to bring its software to life across its various
              user touch points and drive home its new brand identity -
              creative, problem-solving and friendly.
            </p>
          </div>

          <a
            className="main__web-link"
            href="https://www.motionthat.com.au"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit company website"
          >
            motionthat.com.au
          </a>
        </div>

        <section className="where-started">
          <div
            className="where-started__text"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h2>An impactful software identity.</h2>
            <p>
              Founded in 2019, MotionThat required an identity built from
              scratch. Handsome Creative developed a logo and colour palette
              that would best represent the brand’s good-humoured personality.
              Take a look at the journey we went through with MotionThat:
            </p>
          </div>
          <div
            className="where-started__img where-started__img--lg"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <LogoMockupLg />
          </div>
          <div
            className="where-started__img where-started__img--sm"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <LogoMockupSm />
          </div>
        </section>

        <section className="site-and-norm">
          <div
            className="site-and-norm__logo"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <Logo />
          </div>

          <div className="site-and-norm__flex-row">
            <div
              className="animated-norm"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <AnimatedNorm />
            </div>
            <div className="text" data-sal="slide-up" data-sal-duration="500">
              <h3 className="heading">This is NORM.</h3>
              <p className="description">
                Say hi to the brains behind the business! We created NORM to
                give personality to the software powering MotionThat, as well as
                to provide the brand with an added element of fun!
              </p>
            </div>
          </div>
          <div
            className="site-and-norm__laptop-screen"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <video playsInline loop="true" autoPlay="autoplay" muted>
              <source src={websiteVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="wireframes">
          <div
            className="wireframes__text"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <h3 className="heading">
              Dynamic web pages for real-time management.
            </h3>
            <p className="description">
              Before your website represents your brand’s vibe (colours, images,
              animations, and more), we work closely with you to nut out the
              structure and produce wireframes to understand the intended user
              experience completely. It allows us to explore functionality
              options and hierarchy before we dive right in!
            </p>
          </div>

          <div
            className="wireframes__video"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <video playsInline loop="true" autoPlay="autoplay" muted>
              <source src={wireframeVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <section className="responsive">
          <div className="responsive__flex-row">
            <div className="text" data-sal="slide-up" data-sal-duration="500">
              <h3 className="heading">
                A seamless design experience across any platform.
              </h3>
              <p className="description">
                51% of worldwide website traffic is viewed from mobile devices.
                That’s why we choose to design your website for mobile-first. It
                is essential to us, and our delivery philosophy, to ensure your
                website experience and functionality is seamless across all
                devices and browsers.
              </p>
            </div>

            <div className="phone" data-sal="slide-up" data-sal-duration="500">
              <video playsInline loop="true" autoPlay="autoplay" muted>
                <source src={phoneVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>

        <section className="testimonial">
          <div data-sal="slide-up" data-sal-duration="500">
            <span className="testimonial-quotes">“</span>
            <div className="testimonial-msg">
              <p>
                I would recommend Handsome Creative to anyone who needs a
                website, branding or just creative ideas from a{" "}
                <strong>very talented team</strong>.
              </p>
            </div>
            <p className="testimonial-fullname">John Osmond & NORM</p>
            <p className="testimonial-company">MotionThat</p>
          </div>
        </section>

        <section className="print">
          <motion.div
            className="print__norm-arm"
            animate={{ rotate: [15, 0], x: [50, 0] }}
            transition={{
              type: "spring",
              duration: 3,
              yoyo: Infinity,
            }}
          >
            <NormArm />
          </motion.div>

          <div className="print__text">
            <h3 className="heading">Brand on print.</h3>
            <p className="description">
              Traditional marketing has not gone bust. The world is full of
              digitally connected devices but there is always room for good ol’
              tangible print material.
            </p>
          </div>
          <div
            className="print__img"
            data-sal="slide-up"
            data-sal-duration="500"
          >
            <StaticImage
              src="../../images/case-studies/motionthat/handsome-creative-motionthat-business-card-design.png"
              alt="MotionThat Business Cards"
            />
          </div>
        </section>

        <section
          className="stickers"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <Waypoint
            onEnter={() => {
              setIsStickers(true);
            }}
          >
            <div className="stickers__wrapper">
              {isStickers && (
                <div className="sticker sticker--one">
                  <StaticImage
                    src="../../images/case-studies/motionthat/handsome-creative-motionthat-sticker-1.png"
                    alt="MotionThat Sticker"
                  />
                </div>
              )}
              {isStickers && (
                <div className="sticker sticker--two">
                  <StaticImage
                    src="../../images/case-studies/motionthat/handsome-creative-motionthat-sticker-2.png"
                    alt="MotionThat Sticker"
                  />
                </div>
              )}
              {isStickers && (
                <div className="sticker sticker--three">
                  <StaticImage
                    src="../../images/case-studies/motionthat/handsome-creative-motionthat-sticker-3.png"
                    alt="MotionThat Sticker"
                  />
                </div>
              )}
              <div className="stickers__pointer">
                <ArrowCurved />
                <p>And we made stickers!</p>
              </div>
            </div>
          </Waypoint>
        </section>

        <div className="next-btn">
          <LabeledArrow
            label="SHOW ME ANOTHER PROJECT"
            url="/our-work/australian-thermic-lance-company/"
          />
        </div>
        <ContactForm />
      </Wrapper>
    </Layout>
  );
};

export default MotionThatPage;
